import * as React from "react"
import Layout from "../components/layout";
import Team from "../components/elements/team";
import { graphql } from "gatsby"
import {Helmet} from "react-helmet";
import Footer from "../components/footer";
import Header from "../components/header";

const OurTeams = ({data}) => {

    const partners = data.allContentfulPartners.nodes;
    const teams = data.allContentfulTeam.nodes;
    const pages = data.allContentfulPages.nodes

    return (
        <Layout>
            <Helmet>
                <title>Csapataink - Zempléni Hiúzok</title>
            </Helmet>
            <Header pages={pages} teams={teams}/>
            <main>
                <section className="section section-md bg-gray-100">
                    <div className="container">
                        <div className="row row-50">
                            <div className="col-12">
                                <article className="heading-component">
                                    <div className="heading-component-inner">
                                        <h5 className="heading-component-title">Csapataink</h5>
                                    </div>
                                </article>
                                <div className="row row-30">
                                    {teams.map(function(team,index){
                                        return <div key={index} className="col-md-6 col-lg-4">
                                            <Team content={team}/>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer partners={partners}/>
        </Layout>
    )
}

export default OurTeams

export const query = graphql`
  query GetAllTeam {
    allContentfulTeam(sort: {fields: title, order: ASC}) {
        nodes {
        id
        label
        championshipId
        teamId
        title
        image {
          url
        }
        thumbnail {
          url
        }
        }
      }
      allContentfulPartners {
        nodes {
          title
          logo {
            url
          }
        }
      }
      allContentfulPages {
        nodes {
          text {
            raw
          }
          title
          slug
        }
      }
  }
`
