import * as React from "react"
import {Link} from "gatsby";
import noimage from "../../images/noimage.png";

export default function Team(props) {

    const image = props.content.image ? props.content.image.url : noimage;

    return (
        <Link to={`/csapataink/${props.content.label}`}>
            <article className="product">
                <div className="product-header">
                    <div className="product-figure"><img src={image} alt={props.content.title}/></div>
                </div>
                <footer className="product-content">
                    <h6 className="product-title">{props.content.title}</h6>
                    <div className="badge badge-primary">{props.content.label}</div>
                </footer>
            </article>
        </Link>
    )
}

